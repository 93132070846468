import React from "react";
import Layout from "../components/Layout";
import Header from "../components/Header";
import Footer from "../components/Footer";
import styles from "../styles/Map.module.css";
import OpenStreetMap from "../components/OpenStreetMap";
import { useSearchParams } from "react-router-dom";
import { API_URL, PROPERTY_CLASS } from "../components/CONSTS";
import { formatCurrency } from "../components/Utilities";
import { PropertyInterface, ProvinceInterface } from "../components/Interfaces";

const createHtmlContent = (property: PropertyInterface) => {
  return `
    <a href="/property/${property.id}" target="_blank" 
    style="background-color:white;display:flex;gap:10px;padding:5px;border-radius:5px;width:400px;color:#000000;">
      <img src="${
        property.pictureUrl
      }" onerror="this.src='../propertyImagePlaceholder.png'" style="width:120px;height:90px;object-fit:cover;border-radius:5px;"/>
      <div style="font-size:14px;line-height:22px;">
        <div style="font-size:20px;font-weigth:500;padding-bottom:5px;">
          ${formatCurrency(Number(property.price))} ${
    property.priceDescription?.toLowerCase().includes("sq ft") ||
    property.priceDescription?.toLowerCase().includes("sq m")
      ? property.priceDescription
      : ""
  }
        </div>
        <div>${property.address}</div>
        <div>${
          property.neighbourhood || property.community || property.city
        }</div>
        <div style="display:flex;align-items:flex-start;">
          ${
            property.listingType === "SALE"
              ? `<div style="background-color:#4260CC;border-radius:5px;text-align:center;color:#fff;border-radius:5px;padding:3px 10px;font-size:14px;font-weight:500;">
              For Sale
            </div>`
              : `<div style="background-color:#47AC3A;border-radius:5px;text-align:center;color:#fff;border-radius:5px;padding:3px 10px;font-size:14px;font-weight:500;">
              For Lease
            </div>`
          }
        </div>
      </div>
    </a>`;
};

export default function Map() {
  const [searchParams] = useSearchParams();
  const [province, setProvince] = React.useState<ProvinceInterface>();
  const [properties, setProperties] = React.useState<PropertyInterface[]>([]);

  React.useEffect(() => {
    const provinceName =
      searchParams.get("province") ||
      localStorage.getItem("province") ||
      "Ontario";

    fetch(
      `${API_URL}/provinces/query/${JSON.stringify({
        where: { name: provinceName },
      })}`,
      {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Token: String(localStorage.getItem("userToken")),
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data && Array.isArray(data) && data.length > 0) {
          setProvince(data[0]);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [searchParams]);

  React.useEffect(() => {
    if (province) {
      const where = {
        propertyClass: PROPERTY_CLASS,
        province: province.name,
        latitude: {
          gte: province.minLatitude,
          lte: province.maxLatitude,
        },
        longitude: {
          gte: province.minLongitude,
          lte: province.maxLongitude,
        },
      };

      fetch(
        `${API_URL}/properties/query/${JSON.stringify({
          where: where,
        })}`,
        {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
            Token: String(localStorage.getItem("userToken")),
          }),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data) {
            setProperties(
              data.map((property: PropertyInterface) => ({
                text: property.id,
                metadata: createHtmlContent(property),
                latitude: property.latitude,
                longitude: property.longitude,
              }))
            );
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [province]);

  return (
    <Layout>
      <Header />
      <div className={styles.mainContainer}>
        {properties && properties.length > 0 && (
          <OpenStreetMap pushpins={properties} />
        )}
      </div>
      <Footer />
    </Layout>
  );
}
