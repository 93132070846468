import React from "react";
import Layout from "../components/Layout";
import Header from "../components/Header";
import styles from "../styles/Search.module.css";
import { useSearchParams } from "react-router-dom";
import { API_URL, PROPERTY_CLASS } from "../components/CONSTS";
import Property from "../components/Property";
import { formatCurrency } from "../components/Utilities";
import Location from "../components/Location";
import Footer from "../components/Footer";
import OpenStreetMap from "../components/OpenStreetMap";
import {
  PropertyInterface,
  PropertyTypeInterface,
  ProvinceInterface,
} from "../components/Interfaces";
import Loader from "../images/loader.gif";
import L from "leaflet";

const createHtmlContent = (property: PropertyInterface) => {
  return `
    <a href="/property/${property.id}" target="_blank" 
    style="background-color:white;display:flex;gap:10px;padding:5px;border-radius:5px;width:400px;color:#000000;">
      <img src="${
        property.pictureUrl
      }" onerror="this.src='../propertyImagePlaceholder.png'" loading="lazy" style="width:120px;height:90px;object-fit:cover;border-radius:5px;"/>
      <div style="font-size:14px;line-height:22px;">
        <div style="font-size:20px;font-weigth:500;padding-bottom:5px;">
          ${formatCurrency(Number(property.price))} ${
    property.priceDescription?.toLowerCase().includes("sq ft") ||
    property.priceDescription?.toLowerCase().includes("sq m")
      ? property.priceDescription
      : ""
  }
        </div>
        <div>${property.address}</div>
        <div>${
          property.neighbourhood || property.community || property.city
        }</div>
        <div style="display:flex;align-items:flex-start;">
          ${
            property.listingType === "SALE"
              ? `<div style="background-color:#4260CC;border-radius:5px;text-align:center;color:#fff;border-radius:5px;padding:3px 10px;font-size:14px;font-weight:500;">
              For Sale
            </div>`
              : `<div style="background-color:#47AC3A;border-radius:5px;text-align:center;color:#fff;border-radius:5px;padding:3px 10px;font-size:14px;font-weight:500;">
              For Lease
            </div>`
          }
        </div>
      </div>
    </a>`;
};

export default function Search() {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = React.useState(true);
  const [properties, setProperties] = React.useState<PropertyInterface[]>([]);
  const [pushpins, setPushpins] = React.useState([]);
  const [propertiesInMap, setPropertiesInMap] = React.useState<
    PropertyInterface[]
  >([]);
  const [showFilters, setShowFilters] = React.useState(false);
  const [showMore, setShowMore] = React.useState(false);
  const [provinces, setProvinces] = React.useState<ProvinceInterface[]>([]);
  const [province, setProvince] = React.useState(
    searchParams.get("province") || localStorage.getItem("province") || ""
  );
  const [city, setCity] = React.useState(
    searchParams.get("city") || localStorage.getItem("city") || ""
  );
  const [community, setCommunity] = React.useState(
    searchParams.get("community") || localStorage.getItem("community") || ""
  );
  const [neighbourhood, setNeighbourhood] = React.useState(
    searchParams.get("neighbourhood") ||
      localStorage.getItem("neighbourhood") ||
      ""
  );
  const [location, setLocation] = React.useState(
    searchParams.get("location") || localStorage.getItem("location") || ""
  );
  const [propertyTypes, setPropertyTypes] = React.useState<
    PropertyTypeInterface[]
  >([]);
  const [propertyType, setPropertyType] = React.useState(
    Number(searchParams.get("propertyType")) ||
      Number(localStorage.getItem("propertyType")) ||
      0
  );
  const [listingType, setListingType] = React.useState(
    searchParams.get("listingType") || localStorage.getItem("listingType") || ""
  );
  const [status, setStatus] = React.useState("ACTIVE");
  const [minPrice, setMinPrice] = React.useState(0);
  const [maxPrice, setMaxPrice] = React.useState(0);
  const [minSubjectSpace, setMinSubjectSpace] = React.useState(0);
  const [maxSubjectSpace, setMaxSubjectSpace] = React.useState(0);
  const [minLeasableArea, setMinLeasableArea] = React.useState(0);
  const [maxLeasableArea, setMaxLeasableArea] = React.useState(0);
  const [minLandSize, setMinLandSize] = React.useState(0);
  const [maxLandSize, setMaxLandSize] = React.useState(0);
  const [landSizeUnit, setLandSizeUnit] = React.useState(1);
  const [orderBy, setOrderBy] = React.useState({
    date: 1,
    price: 0,
    subjectSpace: 0,
  });
  const [mapBounds, setMapBounds] = React.useState<any>();
  const [map, setMap] = React.useState<any>();

  const [scrollTop, setScrollTop] = React.useState(0);
  React.useEffect(() => {
    const handleScroll = () => {
      setScrollTop(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  React.useEffect(() => {
    if (!showFilters) {
      const selectedProvince = provinces.find((p) => p.name === province);
      if (selectedProvince) {
        setPropertyTypes(selectedProvince.propertyTypes);
        setPropertiesInMap([]);
        setLoading(true);
        fetch(
          `${API_URL}/properties/query/${JSON.stringify({
            where: {
              propertyClass: PROPERTY_CLASS,
              province: province || undefined,
              city: city || undefined,
              community: community || undefined,
              neighbourhood: neighbourhood || undefined,
              latitude: {
                gte: selectedProvince.minLatitude,
                lte: selectedProvince.maxLatitude,
              },
              longitude: {
                gte: selectedProvince.minLongitude,
                lte: selectedProvince.maxLongitude,
              },
              propertyTypeId: propertyType || undefined,
              listingType: listingType || undefined,
              status: status || undefined,
              subjectSpace:
                minSubjectSpace >= 0 && maxSubjectSpace > minSubjectSpace
                  ? {
                      gte: minSubjectSpace,
                      lte: maxSubjectSpace,
                    }
                  : undefined,
              landSize:
                minLandSize >= 0 && maxLandSize > minLandSize
                  ? {
                      gte: minLandSize * landSizeUnit,
                      lte: maxLandSize * landSizeUnit,
                    }
                  : undefined,
              price:
                minPrice >= 0 && maxPrice > minPrice
                  ? {
                      gte: minPrice,
                      lte: maxPrice,
                    }
                  : undefined,
              leasableArea:
                minLeasableArea >= 0 && maxLeasableArea > minLeasableArea
                  ? {
                      gte: minLeasableArea,
                      lte: maxLeasableArea,
                    }
                  : undefined,
            },
            include: {
              propertyType: true,
            },
          })}`,
          {
            method: "GET",
            headers: new Headers({
              "Content-Type": "application/json",
              Token: String(localStorage.getItem("userToken")),
            }),
          }
        )
          .then((res) => res.json())
          .then((data) => {
            if (data) {
              setProperties(data);
              setPropertiesInMap(data);
              setPushpins(
                data.map((property: PropertyInterface) => ({
                  text: property.id,
                  metadata: createHtmlContent(property),
                  latitude: property.latitude,
                  longitude: property.longitude,
                  onMouseOver: () => {
                    const container = document.getElementById(
                      "propertiesContainer"
                    );
                    const anchor = document.getElementById(
                      `property-${property.id}`
                    );
                    const propertyContainers =
                      document.getElementsByClassName("propertyContainer");
                    for (let i = 0; i < propertyContainers.length; i++) {
                      const propertyContainer = propertyContainers[
                        i
                      ] as HTMLElement;
                      propertyContainer.style.backgroundColor = "#ffffff";
                    }
                    if (container && anchor) {
                      setShowMore(true);
                      container.scrollTo({
                        top: anchor.offsetTop - 300,
                        behavior: "smooth",
                      });
                      anchor.style.backgroundColor = "#F0F0F0";
                    }
                  },
                }))
              );
              const container = document.getElementById("propertiesContainer");
              if (container) {
                setShowMore(false);
                container.scrollTo({
                  top: 0,
                });
              }
            }
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, [
    provinces,
    province,
    city,
    community,
    neighbourhood,
    propertyType,
    listingType,
    showFilters,
    status,
    minSubjectSpace,
    maxSubjectSpace,
    minLandSize,
    maxLandSize,
    minPrice,
    maxPrice,
    minLeasableArea,
    maxLeasableArea,
    landSizeUnit,
  ]);

  React.useEffect(() => {
    setLoading(true);
    fetch(
      `${API_URL}/provinces/query/${JSON.stringify({
        include: {
          cities: true,
          communities: true,
          neighbourhoods: true,
          propertyTypes: {
            where: {
              propertyClass: PROPERTY_CLASS,
            },
            orderBy: {
              order: "asc",
            },
          },
        },
      })}`,
      {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Token: String(localStorage.getItem("userToken")),
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setProvinces(data);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    if (mapBounds) {
      setPropertiesInMap(
        properties.filter(
          (p: PropertyInterface) =>
            p.latitude <= mapBounds.getNorth() &&
            p.latitude > mapBounds.getSouth() &&
            p.longitude <= mapBounds.getEast() &&
            p.longitude > mapBounds.getWest()
        )
      );
    }
  }, [mapBounds, properties]);

  return (
    <Layout>
      <Header />
      <div className={styles.mainContainer}>
        <div className={styles.row}>
          <div className={styles.column}>
            <div className={styles.title}>
              {propertiesInMap.length} PROPERTIES
            </div>
            <div className={styles.row}>
              <select
                value={province}
                onChange={(e) => {
                  setProvince(e.target.value);
                  localStorage.setItem("province", e.target.value);
                  setCity("");
                  setCommunity("");
                  setNeighbourhood("");
                  setPropertyType(0);
                  setLocation("");
                }}
              >
                <option disabled>Province</option>
                {provinces.map((p) => (
                  <option key={`province-${p.id}`} value={p.name}>
                    {p.code}
                  </option>
                ))}
              </select>
              {provinces.length > 0 && (
                <Location
                  province={
                    provinces.find((p) => p.name === province) || provinces[0]
                  }
                  setCity={setCity}
                  setCommunity={setCommunity}
                  setNeighbourhood={setNeighbourhood}
                  location={location}
                  setLocation={setLocation}
                />
              )}
              <button
                onClick={() => {
                  setShowFilters(!showFilters);
                }}
              >
                Filters
              </button>
            </div>
            <div
              className={styles.row}
              style={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <div
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                Sort by:
              </div>
              <button
                className={styles.buttonLight}
                onClick={() => {
                  setOrderBy({
                    date: orderBy.date * -1 || -1,
                    price: 0,
                    subjectSpace: 0,
                  });
                }}
                style={{
                  width: "100%",
                  color: orderBy.date === 0 ? "#aaaaaa" : "#344584",
                }}
              >
                {orderBy.date === 0 && "Date"}
                {orderBy.date === 1 && "New to Old"}
                {orderBy.date === -1 && "Old to New"}
              </button>
              <button
                className={styles.buttonLight}
                onClick={() => {
                  setOrderBy({
                    date: 0,
                    price: orderBy.price * -1 || 1,
                    subjectSpace: 0,
                  });
                }}
                style={{
                  width: "100%",
                  color: orderBy.price === 0 ? "#aaaaaa" : "#344584",
                }}
              >
                {orderBy.price === 0 && "Price"}
                {orderBy.price === 1 && "High to Low"}
                {orderBy.price === -1 && "Low to High"}
              </button>
              <button
                className={styles.buttonLight}
                onClick={() => {
                  setOrderBy({
                    date: 0,
                    price: 0,
                    subjectSpace: orderBy.subjectSpace * -1 || 1,
                  });
                }}
                style={{
                  width: "100%",
                  color: orderBy.subjectSpace === 0 ? "#aaaaaa" : "#344584",
                }}
              >
                {orderBy.subjectSpace === 0 && "Size"}
                {orderBy.subjectSpace === 1 && "Large to Small"}
                {orderBy.subjectSpace === -1 && "Small to Large"}
              </button>
            </div>
            <div
              className={styles.flexWraper}
              id="propertiesContainer"
              style={{ overflowY: showMore ? "auto" : "hidden" }}
            >
              {(loading || propertiesInMap.length === 0) && (
                <div className={styles.loader}>
                  <img src={Loader} alt="Loader"></img>
                </div>
              )}
              {!loading &&
                propertiesInMap &&
                propertiesInMap
                  .sort((a, b) => {
                    return (
                      orderBy.date *
                      (new Date(b.listingDate).getTime() -
                        new Date(a.listingDate).getTime())
                    );
                  })
                  .sort((a, b) => {
                    return orderBy.price * (b.price - a.price);
                  })
                  .sort((a, b) => {
                    return (
                      orderBy.subjectSpace * (b.subjectSpace - a.subjectSpace)
                    );
                  })
                  .map((p) => (
                    <div
                      key={`property-${p.id}`}
                      id={`property-${p.id}`}
                      className="propertyContainer"
                      style={{
                        padding: "5px",
                        borderRadius: "5px",
                        transitionDuration: "0.2s",
                      }}
                      onMouseOver={() => {
                        if (map) {
                          L.popup()
                          .setLatLng([p.latitude, p.longitude] )
                          .setContent(p.address)
                          .openOn(map);

                        }
                        const anchor = document.getElementById(
                          `property-${p.id}`
                        );
                        if (anchor) {
                          anchor.style.backgroundColor = "#F0F0F0";
                        }
                      }}
                      onMouseLeave={() => {
                        const anchor = document.getElementById(
                          `property-${p.id}`
                        );
                        if (anchor) {
                          anchor.style.backgroundColor = "#ffffff";
                        }
                      }}
                    >
                      <Property property={p} />
                    </div>
                  ))}
              {propertiesInMap.length > 4 && !showMore && (
                <div className={styles.more}>
                  <button
                    className={styles.buttonLight}
                    onClick={() => {
                      setShowMore(true);
                    }}
                  >
                    LOAD MORE LISTINGS
                  </button>
                </div>
              )}
            </div>
            <div className={styles.footer}>
              <Footer />
            </div>
          </div>
          <div className={styles.column}>
            <div
              className={styles.map}
              style={{ top: Math.max(0, 100 - scrollTop) }}
            >
              {pushpins && pushpins.length > 0 && (
                <OpenStreetMap
                  pushpins={pushpins}
                  onBoundsChanged={({ bounds }) => {
                    setMapBounds(bounds);
                  }}
                  onReady={(m) => {
                    setMap(m);
                  }}
                />
              )}
              {showFilters && (
                <div className={styles.filters}>
                  <div className={styles.row}>
                    <div className={styles.column}>
                      <div>Transaction Type</div>
                      <select
                        value={listingType}
                        onChange={(e) => {
                          setListingType(e.target.value);
                        }}
                      >
                        <option value={""}>All</option>
                        <option value={"SALE"}>For Sale</option>
                        <option value={"LEASE"}>For Lease</option>
                      </select>
                    </div>
                    <div className={styles.column}>
                      <div>Property Type</div>
                      <select
                        value={propertyType}
                        onChange={(e) => {
                          setPropertyType(Number(e.target.value));
                        }}
                        style={{ width: "250px" }}
                      >
                        <option value={0}>All</option>
                        {propertyTypes.map((p) => (
                          <option key={`propertyType-${p.id}`} value={p.id}>
                            {p.displayName || p.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className={styles.column}>
                      <div>Status</div>
                      <select
                        value={status}
                        onChange={(e) => {
                          setStatus(e.target.value);
                        }}
                      >
                        <option>ACTIVE</option>
                        <option>SOLD</option>
                        <option>LEASED</option>
                      </select>
                    </div>
                  </div>
                  <div className={styles.row}>Location</div>
                  <div className={styles.row}>
                    <select
                      value={province}
                      onChange={(e) => {
                        setProvince(e.target.value);
                      }}
                    >
                      <option disabled>Province</option>
                      {provinces.map((p) => (
                        <option key={`province-${p.id}`} value={p.name}>
                          {p.code}
                        </option>
                      ))}
                    </select>
                    {provinces.length > 0 && (
                      <Location
                        province={
                          provinces.find((p) => p.name === province) ||
                          provinces[0]
                        }
                        setCity={setCity}
                        setCommunity={setCommunity}
                        setNeighbourhood={setNeighbourhood}
                        location={location}
                        setLocation={setLocation}
                      />
                    )}
                  </div>
                  <div
                    className={styles.row}
                    style={{
                      borderBottom: "dashed 1px #dddddd",
                      margin: "10px 0px",
                    }}
                  ></div>
                  <div className={styles.row}>
                    <div className={styles.column} style={{ width: "80%" }}>
                      <div className={styles.row}>Price Range</div>
                      <div
                        className={styles.row}
                        style={{ alignItems: "center" }}
                      >
                        <input
                          placeholder="Min"
                          type="number"
                          value={minPrice || ""}
                          onChange={(e) => {
                            setMinPrice(Number(e.target.value));
                          }}
                        />
                        to
                        <input
                          placeholder="Max"
                          type="number"
                          value={maxPrice || ""}
                          onChange={(e) => {
                            setMaxPrice(Number(e.target.value));
                          }}
                        />
                      </div>
                      <div className={styles.row}>Building Size</div>
                      <div
                        className={styles.row}
                        style={{ alignItems: "center" }}
                      >
                        <input
                          placeholder="Min"
                          type="number"
                          value={minSubjectSpace || ""}
                          onChange={(e) => {
                            setMinSubjectSpace(Number(e.target.value));
                          }}
                        />
                        to
                        <input
                          placeholder="Max"
                          type="number"
                          value={maxSubjectSpace || ""}
                          onChange={(e) => {
                            setMaxSubjectSpace(Number(e.target.value));
                          }}
                        />
                      </div>
                      <div className={styles.row}>Leasable Area</div>
                      <div
                        className={styles.row}
                        style={{ alignItems: "center" }}
                      >
                        <input
                          placeholder="Min"
                          type="number"
                          value={minLeasableArea || ""}
                          onChange={(e) => {
                            setMinLeasableArea(Number(e.target.value));
                          }}
                        />
                        to
                        <input
                          placeholder="Max"
                          type="number"
                          value={maxLeasableArea || ""}
                          onChange={(e) => {
                            setMaxLeasableArea(Number(e.target.value));
                          }}
                        />
                      </div>
                      <div
                        className={styles.row}
                        style={{ alignItems: "center" }}
                      >
                        Land Area
                      </div>
                      <div
                        className={styles.row}
                        style={{ alignItems: "center" }}
                      >
                        <input
                          placeholder="Min"
                          type="number"
                          value={minLandSize || ""}
                          onChange={(e) => {
                            setMinLandSize(Number(e.target.value));
                          }}
                        />
                        to
                        <input
                          placeholder="Max"
                          type="number"
                          value={maxLandSize || ""}
                          onChange={(e) => {
                            setMaxLandSize(Number(e.target.value));
                          }}
                        />
                      </div>
                    </div>
                    <div
                      className={styles.column}
                      style={{ width: "20%", position: "relative" }}
                    >
                      <div
                        className={styles.row}
                        style={{ position: "absolute", bottom: "10px" }}
                      >
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <input
                            type="radio"
                            name="landSizeUnit"
                            checked={landSizeUnit === 1}
                            onChange={() => {
                              setLandSizeUnit(1);
                            }}
                          />
                          SQFT
                        </div>
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <input
                            type="radio"
                            name="landSizeUnit"
                            checked={landSizeUnit !== 1}
                            onChange={() => {
                              setLandSizeUnit(43560);
                            }}
                          />
                          Acres
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      borderTop: "solid 1px #dddddd",
                      paddingTop: "10px",
                    }}
                  >
                    <div
                      style={{ display: "flex", float: "right", gap: "10px" }}
                    >
                      <button
                        className={styles.buttonLight}
                        onClick={() => {
                          setCity("");
                          setCommunity("");
                          setNeighbourhood("");
                          setLocation("");
                          setPropertyType(0);
                          setListingType("");
                          setStatus("");
                          setMinLandSize(0);
                          setMaxLandSize(0);
                          setMinSubjectSpace(0);
                          setMaxSubjectSpace(0);
                          setMinLeasableArea(0);
                          setMaxLeasableArea(0);
                          setMinPrice(0);
                          setMaxPrice(0);
                        }}
                      >
                        Reset Filters
                      </button>
                      <button
                        className={styles.buttonDark}
                        onClick={() => {
                          setShowFilters(false);
                        }}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
