import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AppWrapper } from "./components/Context";
import Home from "./pages/Home";
import Search from "./pages/Search";
import Seller from "./pages/Seller";
import About from "./pages/About";
import PageNotFound from "./pages/PageNotFound";
import PropertyDetail from "./pages/PropertyDetail";
import PropertyGallery from "./pages/PropertyGallery";
import AboutDetail from "./pages/AboutDetail";
import PropertyData from "./pages/PropertyData";
import Admin from "./pages/Admin";
import Listing from "./pages/Listing";
import Map from "./pages/Map";
import Listings from "./pages/Listings";

export default function App() {
  return (
    <AppWrapper>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/search" element={<Search />} />
          <Route path="/seller" element={<Seller />} />
          <Route path="/about/:memberName" element={<AboutDetail />} />
          <Route path="/about" element={<About />} />
          <Route path="/property/gallery/:id" element={<PropertyGallery />} />
          <Route path="/property/:id" element={<PropertyDetail />} />
          <Route path="/property/:id/data" element={<PropertyData />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/listing/:id" element={<Listing />} />
          <Route path="/map" element={<Map />} />
          <Route path="/listings/:province/:city" element={<Listings />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </AppWrapper>
  );
}
