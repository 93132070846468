import { ReactComponent as LogoON } from "../icons/logoON.svg";
import { ReactComponent as LogoBC } from "../icons/logoBC.svg";
import { ReactComponent as LogoColoredON } from "../icons/logoColoredON.svg";
import { ReactComponent as LogoColoredBC } from "../icons/logoColoredBC.svg";

//export const API_URL="http://localhost:3001";
export const API_URL = "https://nucommercial.ca/api";

export const SMTP_SENDER = "hr@johome.com";
//export const SMTP_SENDER = "zewei.yang@nustreamrealty.com"; // for test only

export const BC_OFFICE = "karen.chang@nustreamrealty.com";

export const ON_OFFICE = "admin@nustreamtoronto.com";

export const PROPERTY_CLASS = "COMMERCIAL";

export const LOGOS = {
  Ontario: {
    colored: <LogoColoredON />,
    transparent: <LogoON />,
  },
  "British Columbia": {
    colored: <LogoColoredBC />,
    transparent: <LogoBC />,
  },
};

export const ABOUT_US = [
  {
    name: "Wells Peng",
    title: "Founder & CEO",
    summary:
      "Wells Peng graduated from a prestigious university in China with a degree in Mathematics and later obtained an EMBA from the Cheung Kong Graduate School of Business. He worked for 11 years at Lenovo, serving as department general manager for 8 years...",
    description:
      "Wells Peng graduated from a prestigious university in China with a degree in Mathematics and later obtained an EMBA from the Cheung Kong Graduate School of Business. He worked for 11 years at Lenovo, serving as department general manager for 8 years, and participated in investment and acquisition projects. \n\nIn 2006, he immigrated to Vancouver, Canada and became one of the earliest real estate brokers to focus on West Vancouver since 2007. \n\nHe has sold properties worth over CAD 500 million and is a recipient of the Vancouver Real Estate Board's Gold Award in Medallion Club. \n\nIn 2015, he founded \"Nu Stream Realty\" in Vancouver, which attracted investments from Lenovo, IDG Capital, Xiaomi, and many well-known IT and investment investors. \n\nWith a team model, the company opened up the Greater Vancouver area market and replicated the model in Toronto. Currently, the company has over 700 practicing realtors and more than 30 teams, with cumulative sales volume of CAD 16.7 billion, covering various markets. \n\nThe company's biggest feature is to make talented people even better through a close-knit large team model, and to help clients buy and sell properties quickly and effectively with strong support. \n\nWith powerful marketing tools, Nu Stream Realty aims to become the most innovative and fastest-growing real estate brokerage company in the Greater Vancouver area.",
    photo: require("../images/wells.png"),
    biograpgy: [],
  },
  {
    name: "Sonya Jakovickas",
    title: "Managing Broker , Vancouver",
    summary:
      "Sonya has extensive experience in the real estate industry and has been licensed since 1994. Sonya has worked as a Chief Compliance Officer and Managing Broker at Nu Stream Realty Inc. since May 2018. In this role, she manages over 500 REALTORS®...",
    description:
      "Sonya has extensive experience in the real estate industry and has been licensed since 1994. Sonya has worked as a Chief Compliance Officer and Managing Broker at Nu Stream Realty Inc. since May 2018. In this role, she manages over 500 REALTORS® and 25 support staff while ensuring compliance with regulatory bodies such as BCFSA the Greater Vancouver Real Estate, FINTRAC, etc. Prior to her current position, Sonya spent six years as a Legislative Investigator at the Real Estate Council of BC (now BCFSA), where she gained valuable knowledge and expertise in the industry. In 2021, Sonya also completed an Executive MBA program at the Beedie School of Business at Simon Fraser University. Her experience, education, and expertise make her a valuable asset to any real estate team.",
    photo: require("../images/sonya.png"),
    biograpgy: [],
  },
  {
    name: "Brent C. Francis",
    title: "Managing Broker , Vancouver",
    summary:
      "Brent, a licensed realtor for 47 years, pioneered the first Sutton Group franchise in West Vancouver in 1983. As a licensed mortgage broker, he also managed Dominion Lending Franchise and Centum Mortgage franchise while overseeing sub-mortgage brokers.....",
    description:
      `Brent, a licensed realtor for 47 years, pioneered the first Sutton Group franchise in West Vancouver in 1983. As a licensed mortgage broker, he also managed Dominion Lending Franchise and Centum Mortgage franchise while overseeing sub-mortgage brokers.

      He founded "Brent Francis Development Marketing Ltd." and played a key role in projects for developers like Macdonald Development Corp., Strand Developments, Polygon, and BCED, spanning North American locations such as Vancouver, Whistler, Calgary, Edmonton, San Diego, Miami Beach, and Atlanta.
      
      As a managing broker, Brent excelled in residential and commercial divisions, managing sales, leasing, project marketing, and property management. Today, he serves as the company's Fintrac Compliance Officer, ensuring regulatory compliance.
      
      Brent has earned various designations and titles:`,
    photo: require("../images/brent.png"),
    biography: [
      "Bachelor of Commerce Degree, in Urban Land Economics, 1976",
      "B.C real estate agents license since 1978",
      "F. R. I.",
      "R. I. B.C.",
      "Mortgage Brokers license in B.C. since 1998",
      "Accredited Mortgage Professional ( AMP)",
      "Expert witness for BC Assessment Authority"
    ],
  },
  {
    name: "Walter Lui",
    title: "Broker of Record , Toronto",
    summary:
      "Walter Lui, CCIM is a specialist in Commercial Real Estate, and licensed as a Real Estate Broker in Ontario Canada. Walter is also a Certified International Property Specialist, Certified Property Manager, Fellow of Real Estate Institute, Seniors Real Estate...",
    description:
      "Walter Lui, CCIM is a specialist in Commercial Real Estate, and licensed as a Real Estate Broker in Ontario Canada. Walter is also a Certified International Property Specialist, Certified Property Manager, Fellow of Real Estate Institute, Seniors Real Estate Specialist and Certified Real Estate Specialist. Walter has been licensed 38 years in real estate sales, leasing and management in both commercial and residential real estate.",
    photo: require("../images/walter.png"),
    biography: [
      "Certified International Property Specialist (CIPS) November 2017",
      "CCIM JWL Graduate 2014 • Certified Property Manager (CPM®) December 2013 • Institute of Real Estate Management (IREM) Member since November 2012 • Certified Commercial Investment Member (CCIM) October 2011 • Fellow of Real Estate Institute (FRI) designation in 2008 • Certified Real Estate Specialist (CRES) designation in 2008 • Real Estate Broker License in 1990 • Real Estate Sales Representative License in 1984 Specialties: Industrial, Commercial, Investment Sales Leasing and Management • CCIM Region 12 Vice-President 2018 & 2019",
      "CCIM Institute Director-at-large 2020-2021",
      "CCIM Governance Committee member 2020-2021",
      "CCIM Foundation Treasurer and Director-at-large 2020-2021 • Real Estate Institute of Canada National Board of Director 2020-2021 • Canadian Real Estate Association Commercial Council Member • Toronto Real Estate Board Commercial Council Member 2020-2021 • Toronto Real Estate Board Arbitration Committee Panel 202020-2021 • Toronto Real Estate Board Arbitration Committee Mediator 2020-2021",
    ],
  },
  {
    name: "Grace Li",
    title: "VP Commercial , Vancouver",
    summary:
      "As a licensed commercial broker, Grace has over 10 years' expertise in sales and business stretching from architecture to Real Estate. She has been blessed to learn numerous aspects of home | commercial development, including Design, Sales & Marketing....",
    description:
      "As a licensed commercial broker, Grace has over 10 years' expertise in sales and business stretching from architecture to Real Estate. She has been blessed to learn numerous aspects of home | commercial development, including Design, Sales & Marketing, Admin and Leasing. \n\nGrace is familiar with OCP / City Plan, Drawings, Land Use Strategy, Proforma, Development Application, Financing and Land Assembly Procedures, has extensive connections with developers, investors and city planners. \n\nShe has built a professional team Grace Real Estate Team specializes in Commercial | Industrial Sales & Purchase, such as Development Site, Warehouse, Multi-family, Land Assembly, GP/LP, Retail and Project Marketing. \n\nWith our unbeatable marketing resources and massive client's database, Grace Team is delighted to help clients manage investment portfolio to increase fortune and achieve their Real Estate goals. \n\nTel: 778-861-1299 \n\nEmail: gracelirealty@gmail.com",
    photo: require("../images/grace.png"),
    biography: [],
  },
  {
    name: "Anna Zhang",
    title: "VP Commercial I Founding Partner , Vancouver",
    summary:
      "Anna Zhang joined Nu Stream Realty since the beginning as one of the Founding Partners. As VP Commercial, Anna Zhang and her commercial team had successfully conducted deals in differ sectors, including construction developments, large offices...",
    description:
      "Anna Zhang joined Nu Stream Realty since the beginning as one of the Founding Partners. As VP Commercial, Anna Zhang and her commercial team had successfully conducted deals in differ sectors, including construction developments, large offices, warehouse buildings, farms, winery, hotels, and business transactions etc. \n\nPrior to Nu Stream, Anna was working in one of the biggest banks in Canada for over 10 years, and running her consulting business for many years as well. Combining over 20 years of experiences in financing and business consulting, which bring necessary background for her to successfully complete large and sophisticated commercial transactions. \n\nToday Anna Zhang Commercial Team had also grouped a team of young and ambitions members that are also willing to develop in commercial transaction areas. With the more diverse background of our members, our team will be able to serve our clients’ needs in a more efficient and professional way.",
    photo: require("../images/anna.png"),
    biography: [],
  },
  {
    name: "Noah Zhang",
    title: "Commercial Broker , Toronto",
    summary:
      "Noah Zhang is an accomplished Commercial Real Estate professional with over 12 years of experience serving clients in the Toronto Real Estate market. He is widely recognized as a trusted and respected name in the industry, owing to his unwavering...",
    description:
      "Noah Zhang is an accomplished Commercial Real Estate professional with over 12 years of experience serving clients in the Toronto Real Estate market. He is widely recognized as a trusted and respected name in the industry, owing to his unwavering commitment to professionalism and his exceptional ability to understand and cater to his clients' business needs.  \n \nSpecializing in Commercial properties and retail sales and leasing in the Greater Toronto Area (GTA), Noah is renowned for his commitment to transparency, enthusiasm, and in-depth market research. He is dedicated to understanding his clients' unique objectives and tailors his approach to every transaction to ensure their success.  \n \nAdditionally, Noah has an extensive portfolio of active commercial listings, ranging from office spaces to restaurants, as well as a highly sought-after exclusive listing valued at over millions. With his keen market insight and astute understanding of the unique requirements of his clients, Noah is well-equipped to facilitate successful transactions for a diverse range of commercial properties in the Greater Toronto Area. His exceptional track record of results and reputation for excellence make him a highly sought-after professional in the commercial real estate industry.  \n \nNoah's resourcefulness in finding innovative solutions, his tireless efforts in navigating the complexities of the real estate transaction, and his unyielding patience have earned him a reputation for excellence among his clients. He values building strong and friendly relationships with clients over just securing a sale, resulting in repeat business and unwavering client trust.",
    photo: require("../images/noah.png"),
    biography: [],
  },
];
